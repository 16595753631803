import React from "react";
import "./VDNLady.css";
import Irunna from "../../Images/Irunna.jpg";
import Nelson from "../../Images/Nelson.png";
import Patrick from "../../Images/Patrick.jpg";
import Ken from "../../Images/Ken.jpg";

function VDNLady() {
  return (
    <div className="container-fluid HomeTeam" id="ourteam">
      <div className="container">
        <h1>Our Team</h1>
        <div className="ParentTeam">

         
          <div className="ChildTeam">
            <img src={Irunna} alt="" />

            <p>Irunna Ejibe</p>
            <h6>Board Chair</h6>
          </div>

          <div className="ChildTeam2">
            <img src={Patrick} alt="" />

            <p>Patrick Anigbo</p>
            <h6>Director</h6>
          </div>

          <div className="ChildTeam">
            <img src={Ken} alt="" />

            <p>Ken Anyanwu </p>
            <h6>Managing Director</h6>
          </div>

          {/* /Adviser Government Relations */}
          <div className="ChildTeam2">
            <img src={Nelson} alt="" />

            <p>Nelson Okwonna</p>
            <h6>Skills Development Lead</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VDNLady;
