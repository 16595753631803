import React from 'react'
import './VdnServer.css'

import VDNN from "../../Images/ACLogo01.jpg";
import garment from "../../Images/Garment.webp";

function VdnServer() {
  return (
    
    <div className="Home11Container">
    <header className="Home11ParentImg">
      <div className="Home11Child">
        <img src={VDNN} alt="" />

        <h3>Reigniting the Fashion Industry</h3>
        
      </div>
    </header>
  </div>
  )
}

export default VdnServer