import React from "react";
import "./ServicePage.css";
import HomeServices1 from "../Images/HomeServices1.svg";
import HomeFoot1 from "../Images/HomeFoot1.jpg";
import Icon01 from "../Images/Icon01.svg";
import Icon02 from "../Images/Icon02.svg";
import Icon03 from "../Images/Icon03.svg";
import Icon04 from "../Images/Icon04.svg";
import Icon05 from "../Images/Icon05.svg";
import Icon06 from "../Images/Icon06.svg";
import { Link } from "react-router-dom";
import LogoTray from "../Component/Home/LogoTray";
import DataStorage from "../Component/Home/DataStorage";

import CubeSvg from "../Images/3dcube1.svg";
import OurServices from "../Component/Home/OurServices";
import WhatWeDo from "../Component/Home/WhatWeDo";
import VDNLady from "../Component/Home/VDNLady";
import VdnServer from "../Component/Home/VdnServer";
import FooterMobil from "../Component/Home/FooterMobil";
import { Helmet } from "react-helmet";
import {Contact} from "../Component/Home/Modal"


function ServicePage() {
  return (
    <div>
      <Helmet>
        <title> African Cobblers </title>
        <meta
          name="description"
          content=" African Cobblers Resource Centre Limited is an indigenous footwear
          manufacturing and personnel training and development company in
          Aba, Abia State of Nigeria. The company was established in the
          year 2017, with a vision to lead the production of high-quality
          shoes, bags, and other leather products for local consumption and
          export purposes, and train actors within the value chain to
          upskill and modernize to global production standards."
        />
      </Helmet>
      <div className="container-fluid HomeServicesGeneralContainer">
        <div className="container HomeServicesMainParent">
          <div className="HomeServicesParent" id="home">
            <div className="HomeServices1ImgMobile img-responsive">
              <img src={HomeFoot1} alt="" class="img-responsive" />
            </div>

            <h2>We are a leather, garment and footwear manufacturer </h2>

            <p>
            African Cobblers Resource Centre Limited is an indigenous footwear, leather, and garment manufacturing and advocacy, and capacity development company in Aba, Abia State of Nigeria. The company was established in the year 2017, with a vision to lead the production of high-quality shoes, bags, and other leather products for local consumption and export purposes, and train actors within the value chain to upskill and modernize to global production standards.
            </p>

            <div to="" class="modalMove">
          
              <Contact/>
            </div>
          </div>


          <div className="HomeServices1Img">
            <img src={HomeFoot1} alt="" />
          </div>
        </div>
      </div>

      <div className="LogoTray">
        
        <VdnServer />
      </div>

      <div>
        <DataStorage />
      </div>
      <div id="services">
        <OurServices />
      </div>

      <div className="container-fluid Services4GeneralContainer">
        <div className="container">
          <div className="Services4MainContainer">
            <h6>Our Track Record</h6>

            {/* <h3>The Service Pillars Customers all over</h3> */}
          </div>

          <div className="Services4Container">
            <div className="Services4Parent">
              <img src={Icon01} alt="" />
              <h6>Capacity </h6>
              <p>
                <section>
                  a. Production of 19,150 military boots for the Nigerian Army
                </section>
                <section>
                  b. Capacity Development training in leather, footwear, and
                  garments in partnership with Nexim Bank, and NEPC.
                </section>
              </p>
            </div>

            <div className="Services4Parent">
              <img src={Icon02} alt="" />
              <h6>Production </h6>
              <p>
                 Signed an offtake agreement (Memorandum of Understanding) with
                local aggregators and distributors for 9.5 million pairs order,
                pre-production.
              </p>
            </div>

            <div className="Services4Parent">
              <img src={Icon03} alt="" />
              <h6>Global Partners </h6>
              <p>
                <section>
                  a. Engaged key international partners from Brazil to train
                  their personnel in the use of automated footwear production
                  machines. <section></section>b. Export readiness training for
                  Leather manufacturers in Aba
                </section>
              </p>
            </div>

            <div className="Services4Parent">
              <img src={Icon04} alt="" />
              <h6> Consultation </h6>
              <p>
                a. Consultation for a footwear manufacturing firm in Lagos, with
                an annual production capacity of over 2.4million pairs.{" "}
                <section></section>b. Partnership with NILEST – Nigeria’s leading
                leather research institute.
              </p>
            </div>

            <div className="Services4Parent">
              <img src={Icon05} alt="" />
              <h6>ACRC</h6>
              <p>
                a. African Cobblers Resource Centre- 500 shoes daily production
                plant at Aba, Abia State (Completed) <section></section>b. ACRC
                training Programmes- Providing consultancy services, and
                training Programmes for the High Commission of Rwanda.
                <section></section>c. Export partnership agreement with the
                fashion cluster in East Africa.
              </p>
            </div>

            <div className="Services4Parent">
              <img src={Icon06} alt="" />
              <h6>Training </h6>
              <p>
                Training and development of >26,000 individuals in the
                leather, footwear, and garment industry.
              </p>
            </div>
          </div>
        </div>
      </div>

      <WhatWeDo />
      
      {/* <LogoTray /> */}
      <LogoTray />
      <VDNLady />
      <FooterMobil />
    </div>
  );
}

export default ServicePage;
