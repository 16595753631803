import React from "react";
import "./OurServices.css";
import { Link } from "react-router-dom";
import WorkSpace from "../../Images/WorkSpace.jpeg";
import Cluster from "../../Images/Cluster.jpg";
import Satelite from "../../Images/Satelitee.svg";
import Training from "../../Images/Training.jpg";
import arrow from "../../Images/arrow-right.svg"

function OurServices() {
  return (
    <div className="container-fluid HomePage2MainBody">
      <div className="container">
        <div className="HomePage2Body">
          <h6>Our services</h6>
          <h3>
            We're a major player at the fore of changing the narrative that
            Nigerian fashion items are substandard, predominantly through:
          </h3>
        </div>

        <div className="HomePage2Flex">
          <div className="HomePage2Cards">
            <div class="card">
              <img
                src={WorkSpace}
                class="card-img-top carditUp"
                alt="Card image"
              />

              <div class="card-body">
                <div className="minheight">
                  <h4 class="card-title">Advocacy</h4>

                  <p class="card-text">
                    The leather, garment and footwear industry remains an ace
                    card for economic development, and job creation for young
                    people in Nigeria
                  </p>
                </div>


                <button className="HomePage2Button">
                  <div>
                    Learn more  <img src={arrow} alt="" />

                  </div>

                </button>

              </div>
            </div>
          </div>

          <div className="HomePage2Cards">
            <div class="card">
              <img
                src={Cluster}
                class="card-img-top carditUp"
                alt="Card image"
              />

              <div class="card-body">
                <div className="minheight">
                  <h4 class="card-title">Capacity Development</h4>

                  <p class="card-text">
                    The leather, garment and footwear industry remains an ace
                    card for economic development, and job creation for young
                    people in Nigeria
                  </p>
                </div>


                <button className="HomePage2Button">

                  Learn more <img src={arrow} alt="" />


                </button>

              </div>
            </div>
          </div>

          <div className="HomePage2Cards">
            <div class="card">
              <img
                src={Training}
                class="card-img-top carditUp"
                alt="Card image"
              />

              <div class="card-body">
                <div className="minheight">
                  <h4 class="card-title">Workspace</h4>

                  <p class="card-text">
                    The leather, garment and footwear industry remains an ace
                    card for economic development, and job creation for young
                    people in Nigeria
                  </p>
                </div>


                <button className="HomePage2Button">
                  {/* <Link to="" class="x"> */}
                  Learn more <img src={arrow} alt="" />
                  {/* </Link> */}

                </button>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
