import React from "react";
import "./LogoTray.css";

import AcPartner1 from "../../Images/ACPartner1.png";
import AcPartner2 from "../../Images/ACPartner2.png";
import Abia from "../../Images/GAbia.jpg"
import AcPartner3 from "../../Images/ACPartner3.png";
import AcPartner4 from "../../Images/ACPartner4.png";
import Nexim from "../../Images/Nexim.jpg"
import AcPartner5 from "../../Images/ACPartner5.png";
import AcPartner6 from "../../Images/ACPartner6.png";
import AcPartner7 from "../../Images/ACPartner7.png";
import AcPartner8 from "../../Images/ACPartner8.png";
import AcPartner9 from "../../Images/ACPartner9.png";
import AcPartner10 from "../../Images/ACPartner10.png";
import AcPartner11 from "../../Images/ACPartner11.png";
import AcPartner12 from "../../Images/AcPartner12.png";
import AcPartner13 from "../../Images/ACPartner13.png";
import AcPartner14 from "../../Images/ACPartner14.png";

function LogoTray() {
  return (
    <div className="container-fluid">
      <div className="container mobilecenter">
        <div className="LogoTrayParent">
          <h4>Our Partners</h4>
        </div>
        <div className="LogoTrayChildren">
          <img src={AcPartner1} alt="" />
          <img src={Abia} alt="" />
          <img src={AcPartner3} alt="" />
          <img src={Nexim} alt="" />
          <img src={AcPartner5} alt="" />
          <img src={AcPartner6} alt="" />
          <img src={AcPartner7} alt="" />
          <img src={AcPartner8} alt="" />
          <img src={AcPartner9} alt="" />
          <img src={AcPartner10} alt="" />
          <img src={AcPartner11} alt="" />
          <img src={AcPartner12} alt="" />
          {/* <img src={AcPartner13} alt="" /> */}
          <img src={AcPartner14} alt="" />
        </div>

        <div className="LogoTrayChildrenMobile">
        <img src={AcPartner7} alt="" />
          <img src={AcPartner8} alt="" />
          <img src={AcPartner12} alt="" />
        </div>
      </div>
    </div>
  );
}

export default LogoTray;
