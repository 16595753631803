import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
// import Black from "../Images/VDN Logo + Text.svg";
import ACLogo from "../Images/ACLogo01.jpg";
import Ham from "../Images/Hamburger.svg"
import { NavHashLink } from "react-router-hash-link";
import { Contact2 } from "../Component/Home/Modal2";

function Navbar() {
  return (
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark ">
      <div class="container">
        <Link to="/" class="navbar-brand logo ">
          <img src={ACLogo} alt="African Cobblers Logo" />
        </Link>



        <button
          class="navbar-toggler ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          {/* <span class="navbar-toggler-icon"></span> */}
          <img src={Ham} alt="African Cobblers Resource Center" />
        </button>

        <div class="collapse navbar-collapse text-center justify-content-center " id="collapsibleNavbar">
          <ul class="navbar-nav">


            <li class="nav-item active">
              <NavHashLink
                to="#home"
                scroll={(el) =>
                  el.scrollIntoView({ behavior: "smooth", block: "end" })
                }
              >
                Home
              </NavHashLink>


            </li>

            <li class="nav-item">
              <NavHashLink
                to="#services"
                scroll={(el) =>
                  el.scrollIntoView({ behavior: "smooth", block: "end" })
                }
              >
                Services
              </NavHashLink>

            </li>

            <li class="nav-item">
              <NavHashLink
                to="#products"
                scroll={(el) =>
                  el.scrollIntoView({ behavior: "smooth", block: "end" })
                }
              >
                Products
              </NavHashLink>

            </li>

            <li class="nav-item">
              <NavHashLink
                to="#ourteam"
                scroll={(el) =>
                  el.scrollIntoView({ behavior: "smooth", block: "end" })
                }
              >
                Our Team
              </NavHashLink>

            </li>


          </ul>
        </div>

        <div className="Navbutton">
          {/* <button type="button" class="  NavText d-none d-lg-block">
            Contact
          </button> */}
          <Contact2/>
        </div>

      </div>
    </nav>
  );
}

export default Navbar;
