
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import ServicePage from './ServicePage/ServicePage';
import { Helmet } from "react-helmet";

function App() {
  return (
    <Router >


  
      <Navbar />

      <Helmet>
        <title> African Cobblers Resource Centre </title>
        <meta
        name= "description"
        content= "African Cobblers Resource Centre Limited is an indigenous footwear
        manufacturing and personnel training and development company in
        Aba Abia State of Nigeria The company was established in the
        year 2017 with a vision to lead the production of high-quality
        shoes, bags, and other leather products for local consumption and
        export purposes, and train actors within the value chain to
        upskill and modernize to global production standards"
        />
        <meta
        name = "keywords"
        content= "African Cobblers, African Cobbler, Capacity Building, African Cobbler Resource Centre, ACRC, Indigenous Footwear and leather"
        
        />
      </Helmet>

      <Routes>

       

        <Route path='/' element={ <ServicePage/>} />
       


      
      </Routes>
   
      <Footer/>

    </Router>

  );
}

export default App;
