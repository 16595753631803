import React from "react";
import "./WhatWeDo.css";
import Shoe1 from "../../Images/Shoe1.png";
import Shoe2 from "../../Images/Shoe2.png";
import Shoe3 from "../../Images/Shoe3.png";
import Fashion02 from "../../Images/Fashion2.png";
import Fashion01 from "../../Images/Fashion01.png";
import Boots01 from "../../Images/Boots01.jpg";
import Boots02 from "../../Images/Boots02.jpg";
import Boots03 from "../../Images/Boots03.jpg";
import Boots04 from "../../Images/Boots04.webp";
import Boots05 from "../../Images/Boots05.jpg";
import Boots06 from "../../Images/Boots06.webp";
import Boots07 from "../../Images/Boots07.webp";
import Boots08 from "../../Images/Boots08.jpg";
import Boots09 from "../../Images/Boots09.jpg";
import Boots10 from "../../Images/Boots10.jpg";
import Boots11 from "../../Images/Boots11.jpg";
import Boots12 from "../../Images/Boots12.jpg";
import Boots13 from "../../Images/Boots13.jpg";
import Boots14 from "../../Images/Boots14.jpg";
import Boots15 from "../../Images/Boots15.jpg";
import Boots16 from "../../Images/Boots16.jpg";

function WhatWeDo() {
  return (
    <div className="container-fluid Home7PageMain" id="products">
      <div className="container HomePage7Body">
      <h4>Our Poducts</h4>
        <div
          id="carouselExampleIndicators"
          class="carousel slide homePageChild"
          data-bs-ride="carousel"
        >

          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="6"
              aria-label="Slide 7"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="7"
              aria-label="Slide 8"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="8"
              aria-label="Slide 9"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="9"
              aria-label="Slide 10"
            ></button>
          </div>

          <div class="carousel-inner ">
            <div class="carousel-item active HomePage7Images">
              <img src= {Boots01} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item HomePage7Images">
              <img src= {Boots08} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item HomePage7Images">
              <img src= {Boots09} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item HomePage7Images">
              <img src= {Boots10} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item HomePage7Images">
              <img src= {Boots11} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item HomePage7Images">
              <img src= {Boots12} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item HomePage7Images">
              <img src= {Boots13} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item HomePage7Images">
              <img src= {Boots14} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item HomePage7Images">
              <img src= {Boots15} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item HomePage7Images">
              <img src= {Boots16} class="d-block w-100" alt="..." />
            </div>
          </div>

          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo;
