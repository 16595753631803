import React, { useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import "./Modal.css";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

export const Contact =()=> {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const form = useRef();

  const sendEmail = (e) => {

    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;

    if(!name || !email || !message){
      return toast.error("Fill out all the fields", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
    

    emailjs.send(
        "service_ul0cwsi",
        "template_u23dp9q",
        form.current,
        "Ojv15YXa_sDnSvmGF"
      ).then(
        (result) => {
            
          console.log(result.text);
     toast.success("Message suuccessfully sent", {
      position: toast.POSITION.BOTTOM_RIGHT,
     })
        },
        (error) => {
        //   console.log(error.text);
          console.log(error.text)
        }
      );
      e.target.reset();
  };


  return (
    <>
      <Button onClick={handleShow} className="Button">
        Connect with us
      </Button>

      <Modal show={show} onHide={handleClose} className="modalbodyshow">
        <Modal.Header closeButton></Modal.Header>

        <div className="container modalHeader">
          <Modal.Title>
            <p className="modalTitle mt-3 ">Connect with us</p>
          </Modal.Title>

          {/* <Modal.Title className= {classes.modalSubTitle}>
              <p>
                Store data safely for your business 
              </p>
            </Modal.Title> */}
        </div>
        <form ref={form} onSubmit={sendEmail} className="container form">
        <Modal.Body className="modalBody">
          
            <label className="label">Name</label>
            <input type="text" name="user_name" className="input" />
            <label className="label">Email</label>
            <input type="email" name="from_email" className="input" />
            <label className="label">Message</label>
            <textarea name="message" className="textarea" />
         
        </Modal.Body>

        
        </form>
        <Modal.Footer className="formFooter">
          <Button
            variant="primary"
            onClick={handleClose}
            className="container formFooter2"
          >
             <input type="submit" value="Send" className="input " />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// export default Modals;
