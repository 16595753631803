import React from "react";
import "./DataStorage.css";
import BusinessDay from "../../Images/businessday.jpg";
import BusinessAm from "../../Images/businessam.jpg";
import ThisDay from "../../Images/ThisDay.jpg";
import ICIR from "../../Images/ICIR.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function DataStorage() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  
  return (
    <div className="container-fluid DataStorageMainBody">
      <div className="container DataStorageBody">
        <div className="DataStorageText">
          <h4>Updates and news</h4>
        </div>

        <div className="CarouselParent">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            <a
              href="https://businessday.ng/news/article/african-cobblers-spearheads-automation-of-aba-made-shoes/"
              class=""
              target="_blank"
              rel="noreferrer"
            >
              <div class="card CardImage">
                <img src={BusinessDay} class="card-img-top " alt="African Cobblers in the news or media"/>

                <div class="card-body">
                  <div className="CardBody">
                    <h4 class="card-title">
                      African Cobblers spearheads automation of Aba-made shoes
                    </h4>

                    <p class="card-text">
                    The dream to automate shoe production in Aba, the commercial hub of Abia State, is coming to fruition, as African Cobblers Limited, an indigenous shoe manufacturing firm
                    </p>
                  </div>

                 
                    <button className="button">Read more</button>
                
                </div>
              </div>
            </a>
            <a
              href="https://www.businessamlive.com/shoemaker-seeks-patronage-for-locally-made-products-to-grow-sector/"
              class=""
              target="_blank"
              rel="noreferrer"
            >
              <div class="card CardImage">
                <img src={BusinessAm} class="card-img-top " alt="African Cobblers in the news or media" />

                <div class="card-body">
                  <div className="CardBody">
                    <h4 class="card-title">
                    Shoemaker seeks patronage for locally made products to grow sector
                    </h4>

                    <p class="card-text">
                    Ken Anyanwu, national secretary, Association of Leather and Allied Industrialists of Nigeria, an association of shoe, bag and belt makers in the country, has appealed to Nigerians to patronise locally made shoes...
                    </p>
                  </div>

                 
                    <button className="button">Read more</button>
                
                </div>
              </div>
            </a>
            <a
              href="https://www.thisdaylive.com/index.php/2020/11/29/na-aba-made-a-case-study-on-aba-shoe-industry/"
              class=""
              target="_blank"
              rel="noreferrer"
            >
              <div class="card CardImage">
                <img src={ThisDay} class="card-img-top " alt="African Cobblers in the news or media" />

                <div class="card-body">
                  <div className="CardBody">
                    <h4 class="card-title">
                    Na Aba Made: A Case Study on Aba Shoe Industry
                    </h4>

                    <p class="card-text">
                    According to Ken Anyanwu, National Secretary of the Association of Leather and Allied Industrialists of Nigeria, “more than 60per cent of new shoes worn in Nigeria come from Aba
                    </p>
                  </div>

                 
                    <button className="button">Read more</button>
                
                </div>
              </div>
            </a>
            <a
              href="https://www.icirnigeria.org/as-nigerian-government-slumbers-n144bn-aba-shoe-industry-crawls/"
              class=""
              target="_blank"
              rel="noreferrer"
            >
              <div class="card CardImage">
                <img src={ICIR} class="card-img-top " alt="African Cobblers in the news or media" />

                <div class="card-body">
                  <div className="CardBody">
                    <h4 class="card-title">
                    As Nigerian government slumbers, N144bn Aba shoe industry crawls
                    </h4>

                    <p class="card-text">
                    But Secretary of the Association of Leather and Allied Industrialists of Nigeria (ALAIN) Ken Anyanwu estimates that there are over 200,000 entrepreneurs in the cluster.
                    </p>
                  </div>

                 
                    <button className="button">Read more</button>
                
                </div>
              </div>
            </a>

            
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default DataStorage;
